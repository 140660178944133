const KILOMETER_TO_MILE_RATIO = 0.621371
const METER_TO_FEET_RATIO = 3.28084

export const metersToKilometers = (value: number): number => value / 1000

export const metersToMiles = (value: number): number =>
  metersToKilometers(value) * KILOMETER_TO_MILE_RATIO

export const metersToFeet = (value: number): number => value * METER_TO_FEET_RATIO

export const kilometersToMiles = (value: number): number => value * KILOMETER_TO_MILE_RATIO

export const kmphToMph = kilometersToMiles
