import React from 'react'

export enum MeasurementSystem {
  Metric = 'Metric',
  Imperial = 'Imperial',
}

export enum MetricUnit {
  PathLength = 'm',
  Speed = 'km/h',
}

export enum ImperialUnit {
  PathLength = 'ft',
  Speed = 'mph',
}

export type LocalizeNumberFn = (val: number, precision?: number) => number

export type LocalizationContext = {
  measurementSystem: MeasurementSystem
  changeMeasurementSystem: (system: MeasurementSystem) => void

  unitPathLength: MetricUnit.PathLength | ImperialUnit.PathLength
  unitSpeed: MetricUnit.Speed | ImperialUnit.Speed

  localizePathLength: LocalizeNumberFn
  localizeSpeed: LocalizeNumberFn
}

export const LocalizationContext = React.createContext<LocalizationContext | null>(null)
