import React, { Component } from 'react'
import Loadable from 'react-loadable'
import { HashRouter, Route, Switch } from 'react-router-dom'

import './App.scss'
import { LocalizationContextProvider } from './components/localization/LocalizationContextProvider'
import Login from './Login'

const LoadingSpinner = () => (
  <div className='animated fadeIn pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'></div>
  </div>
)

// Containers
const ApplicationLayout = Loadable({
  loader: () => import('./views/core/ApplicationLayout'),
  loading: LoadingSpinner,
})

class App extends Component<{}, { loggedIn: boolean }> {
  public state = {
    loggedIn: true,
  }

  onLogin = () => {
    this.setState({
      loggedIn: true,
    })
  }

  render() {
    return (
      <LocalizationContextProvider>
        {!this.state.loggedIn ? (
          <Login onSuccess={this.onLogin} />
        ) : (
          <HashRouter>
            <Switch>
              <Route path='/' name='Home' component={ApplicationLayout} />
            </Switch>
          </HashRouter>
        )}
      </LocalizationContextProvider>
    )
  }
}

export default App
