import React, { useState } from 'react'

import { toPrecision } from '../../helpers/number'
import { kmphToMph, metersToFeet } from '../../helpers/units'

import {
  ImperialUnit,
  LocalizationContext,
  MeasurementSystem,
  MetricUnit,
} from './LocalizationContext'

export type LocalizationContextSettings = {
  measurementSystem: MeasurementSystem
}

type MeasurementSystemSpecificConfig = Omit<
  LocalizationContext,
  'measurementSystem' | 'changeMeasurementSystem'
>

function identity<T>(value: T): T {
  return value
}

const metricConfig: MeasurementSystemSpecificConfig = {
  unitPathLength: MetricUnit.PathLength,
  unitSpeed: MetricUnit.Speed,

  localizePathLength: (value, precision = 2) => toPrecision(value, precision),
  localizeSpeed: identity,
}

const imperialConfig: MeasurementSystemSpecificConfig = {
  unitPathLength: ImperialUnit.PathLength,
  unitSpeed: ImperialUnit.Speed,

  localizePathLength: (value, precision = 2) => toPrecision(metersToFeet(value), precision),
  localizeSpeed: (value, precision = 2) => toPrecision(kmphToMph(value), precision),
}

export const LocalizationContextProvider: React.FC = ({ children }) => {
  const [measurementSystem, changeMeasurementSystem] = useState(MeasurementSystem.Imperial)

  const context: LocalizationContext = {
    measurementSystem,
    changeMeasurementSystem,
    ...(measurementSystem === MeasurementSystem.Imperial ? imperialConfig : metricConfig),
  }

  return <LocalizationContext.Provider value={context}>{children}</LocalizationContext.Provider>
}
